import {Auth} from "aws-amplify";

const getAuthenticatedUserToken = async () : Promise<string> => {
    return new Promise( (resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                const idToken = user.signInUserSession.idToken.getJwtToken();
                resolve(idToken);
            })
            .catch(ex => {
                console.error(ex);
                reject(ex.message);
            })
    });
}

const fetchWithToken = async (url: RequestInfo, method: string) : Promise<{ statusCode: number, data: any, message: string } | null> => {
    try {
        const idToken = await getAuthenticatedUserToken();
        const headers = {
            'Authorization': idToken
        }
        const response = await fetch(url, {method: method, headers: headers});
        return await response.json();
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

const fetchWithTokenAndBody = async (url: string, method: string, body: any) : Promise<{ statusCode: number, data: any, message: string } | null> => {
    try {
        const idToken = await getAuthenticatedUserToken();
        const headers = {
            'Authorization': idToken,
            'Content-Type': 'application/json',
        }
        const response = await fetch(url, {method: method, headers, body: JSON.stringify(body)});
        return await response.json();
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

export {
    fetchWithToken,
    fetchWithTokenAndBody,
    getAuthenticatedUserToken
}