// General Constants
export const FUNCTION: string = "Function";
export const INGREDIENT: string = "Ingredient";
export const AUTHOR: string = "Author";
export const CATEGORY_AND_STATUS: string = "Category & Status";
export const STATUSES: string = "Statuses";
export const ACCEPTABILITY: string = "acceptability";
export const CLASSIFICATION: string = "Classification";
export const FEDERATE: string = "Federate";
export const MASTERS = "masters";

// Admin Table Constants
export const CREATED_BY: string = "createdBy";
export const CREATED_ON: string = "createdOn";
export const UPDATED_BY: string = "updatedBy";
export const UPDATED_ON: string = "updatedOn";
export const DATE: string = "Date Created";
export const MODIFIED_DATE: string = "Modified Date";
export const ASSOCIATED_INGREDIENTS_CONSTANT: string = "Associated Ingredients";
export const CLASSIFICATION_CONSTANT: string = "classification";
export const FUNCTION_CONSTANT: string = "functions";

// Cognito
export const CUSTOM_ROLE: string = "custom:role";

// Time Constants
export const AM: string = "AM";
export const PM: string = "PM";

// Date Constants
export const CENTURY: number = 20;
export const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
];

// Admin Constants
export const adminColumnList = [
    {columnName: ASSOCIATED_INGREDIENTS_CONSTANT, columnMapping: MASTERS},
    {columnName: CATEGORY_AND_STATUS, columnMapping: ACCEPTABILITY},
    {columnName: DATE, columnMapping: CREATED_ON},
    {columnName: MODIFIED_DATE, columnMapping: UPDATED_ON},
    {columnName: CLASSIFICATION, columnMapping: CLASSIFICATION_CONSTANT},
    {columnName: FUNCTION, columnMapping: FUNCTION_CONSTANT},
]

export const visibilities: string[] = ["Publish", "Draft"];

// Search Constants
export type SelectMode = 'multiple' | 'tags';
export const SelectModes: Record<SelectMode, SelectMode> = {
    'multiple': 'multiple',
    'tags': 'tags'
};

export const NORMAL_SEARCH: string = "Search..."
export const INGREDIENT_SEARCH: string = "Enter ingredients here"

// Routes
export const HOME_PAGE: string = "/";
export const GLOSSARY_PAGE: string = "/glossary";
export const SUMMARY_PAGE: string = "/summary";
export const BULK_UPLOAD_PAGE: string = "/bulkupload";
export const FUNCTION_DESCRIPTION_PAGE: string = "/glossary/:id";
export const ADMIN_PAGE: string = "/admin";
export const ADD_INGREDIENT_PAGE: string = "/admin/add/ingredient";
export const EDIT_INGREDIENT_PAGE: string = "/admin/edit/ingredient/:id";
export const NOT_FOUND_PAGE: string = "/not-found";

// Messages
export const ADMIN_DASHBOARD = "Admin Dashboard"
export const TOOLTIP_ADMIN: string = "Toggle to switch to Admin Dashboard"
export const TOOLTIP_INGREDIENT: string = "Toggle to switch to Ingredient List"
export const UNSAVED_FORM_MESSAGE: string = "You have unsaved changes. Are you sure you want to leave?";
