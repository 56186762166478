import { Ingredient } from "src/types/ingredient";
import {
    STATUS_ACCEPTABLE,
    STATUS_UNACCEPTABLE,
    STATUS_RESTRICTED,
    STATUS_UNREVIEWED,
    STATUS_CLOSE_MATCHES,
    UNACCEPTABLE_WITH_QUALIFIER,
    ACCEPTABLE_WITH_QUALIFIER,
    STATUS_LABELS,
    SummaryDetail,
    CloseMatchDetails,
    SummaryData
} from "src/constants/summaryConstants";

export const getSummaryData = (
    data: Ingredient[],
    closeMatchData: Ingredient[],
    unreviewedData: string[],
    categoryFilter: string
): SummaryData[] => {
    const summaryMap: { [status: string]: { count: number; details: SummaryDetail[] | CloseMatchDetails; categoryCounts?: { [key: string]: number } } } = {
        [STATUS_ACCEPTABLE]: { count: 0, details: [] },
        [STATUS_UNACCEPTABLE]: { count: 0, details: [] },
        [STATUS_RESTRICTED]: { count: 0, details: [] },
        [STATUS_UNREVIEWED]: { count: 0, details: [] },
        [STATUS_CLOSE_MATCHES]: { count: 0, details: {
                [STATUS_ACCEPTABLE]: [],
                [STATUS_UNACCEPTABLE]: [],
                [STATUS_RESTRICTED]: []
            } as CloseMatchDetails, categoryCounts: { [STATUS_ACCEPTABLE]: 0, [STATUS_UNACCEPTABLE]: 0, [STATUS_RESTRICTED]: 0 } }
    };

    const uniqueIngredients: { [status: string]: Set<string> } = {
        [STATUS_ACCEPTABLE]: new Set(),
        [STATUS_UNACCEPTABLE]: new Set(),
        [STATUS_RESTRICTED]: new Set(),
        [STATUS_UNREVIEWED]: new Set(),
        [STATUS_CLOSE_MATCHES]: new Set()
    };

    data.forEach(ingredient => {
        let addedToStatus = false;

        ingredient.acceptability.forEach(acc => {
            let status = acc.status.trim();
            const category = acc.type.trim();
            const qualifier = acc.qualifier;

            if (status === UNACCEPTABLE_WITH_QUALIFIER || status === ACCEPTABLE_WITH_QUALIFIER) {
                status = STATUS_RESTRICTED;
            }

            if (category === categoryFilter && !uniqueIngredients[status].has(ingredient.id)) {
                summaryMap[status].count++;
                uniqueIngredients[status].add(ingredient.id);

                (summaryMap[status].details as SummaryDetail[]).push({
                    ingredientName: [ingredient.name],
                    ingredientId: [ingredient.id],
                    qualifier: qualifier || ""
                });

                addedToStatus = true;
            }
        });

        if (!addedToStatus && !uniqueIngredients[STATUS_UNREVIEWED].has(ingredient.id)) {
            uniqueIngredients[STATUS_UNREVIEWED].add(ingredient.id);
            summaryMap[STATUS_UNREVIEWED].count++;
            (summaryMap[STATUS_UNREVIEWED].details as SummaryDetail[]).push({
                ingredientName: [ingredient.name],
                ingredientId: [ingredient.id],
                qualifier: ""
            });
        }
    });

    closeMatchData.forEach(ingredient => {
        const ingredientStatus = ingredient.acceptability.find(acc => acc.type.trim() === categoryFilter)?.status.trim();

        if (ingredientStatus) {
            let status = ingredientStatus === UNACCEPTABLE_WITH_QUALIFIER || ingredientStatus === ACCEPTABLE_WITH_QUALIFIER
                ? STATUS_RESTRICTED
                : ingredientStatus;
            const qualifier = ingredient.acceptability.find(acc => acc.type.trim() === categoryFilter)?.qualifier.trim();

            if (!uniqueIngredients[STATUS_CLOSE_MATCHES].has(ingredient.id)) {
                uniqueIngredients[STATUS_CLOSE_MATCHES].add(ingredient.id);
                summaryMap[STATUS_CLOSE_MATCHES].count++;

                const closeMatchDetails = summaryMap[STATUS_CLOSE_MATCHES].details as CloseMatchDetails;

                if (status === STATUS_ACCEPTABLE || status === STATUS_UNACCEPTABLE || status === STATUS_RESTRICTED) {
                    closeMatchDetails[status].push({
                        ingredientName: [ingredient.name],
                        ingredientId: [ingredient.id],
                        qualifier: qualifier || ""
                    });
                    summaryMap[STATUS_CLOSE_MATCHES].categoryCounts![status]++;
                }
            }
        }
    });

    unreviewedData.forEach(ingredient => {
        summaryMap[STATUS_UNREVIEWED].count++;
        (summaryMap[STATUS_UNREVIEWED].details as SummaryDetail[]).push({
            ingredientName: [ingredient],
            ingredientId: [ingredient],
            qualifier: ""
        });
    });

    const capitalizeFirstLetter = (status: string): string => {
        return status.charAt(0).toUpperCase() + status.slice(1);
    };

    return Object.keys(summaryMap).map(status => ({
        status: STATUS_LABELS[status] || capitalizeFirstLetter(status),
        count: summaryMap[status].count,
        details: summaryMap[status].details,
        categoryCounts: summaryMap[status].categoryCounts
    }) as SummaryData).sort((a, b) => {
        if (a.status === STATUS_LABELS[STATUS_CLOSE_MATCHES]) return 1;
        if (b.status === STATUS_LABELS[STATUS_CLOSE_MATCHES]) return -1;
        return a.status === STATUS_LABELS[STATUS_UNREVIEWED] ? 1 : b.status === STATUS_LABELS[STATUS_UNREVIEWED] ? -1 : 0;
    });
};
