import React from 'react';
import { Table, Button, message } from 'antd';

interface MetaDataTableProps {
    data: any[];
    onStatusClick: (statusId: string, type: string) => void;
    loading: boolean;
}

export const MetaDataTable: React.FC<MetaDataTableProps> = ({ data, onStatusClick, loading }) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            key: 'uploadedBy',
            sorter: (a: any, b: any) => a.uploadedBy.localeCompare(b.uploadedBy),
        },
        {
            title: 'File Name',
            dataIndex: 'fileName',
            key: 'fileName',
            sorter: (a: any, b: any) => a.fileName.localeCompare(b.fileName),
            render: (text: string, record: any) => (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        onStatusClick(record.id, "upload");
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: 'Uploaded At',
            dataIndex: 'uploadedAt',
            key: 'uploadedAt',
            sorter: (a: any, b: any) => new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime(),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => (
                <Button
                    onClick={() => onStatusClick(record.id, "report")}
                    disabled={text !== "Uploaded Succesfully"}
                >
                    {text === "Uploaded Succesfully" ? 'Download Report' : text}
                </Button>
            ),
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            rowKey="id"
        />
    );
};
