import React, { useState } from "react";
import { Table, Collapse, message } from "antd";
import type { ColumnProps } from "antd/es/table";
import { Ingredient } from "src/types/ingredient";
import { getSummaryData } from "src/utils/components/common/SummaryTableUtil";
import { ingredientsTableStyle } from "src/constants/styleConstants";
import { Popover } from "antd";
import {
    STATUS_ACCEPTABLE,
    STATUS_UNACCEPTABLE,
    STATUS_RESTRICTED,
    STATUS_UNREVIEWED,
    STATUS_CLOSE_MATCHES,
    STATUS_LABELS,
    SummaryDetail,
    CloseMatchDetails,
    SummaryData
} from "src/constants/summaryConstants";

const { Panel } = Collapse;

export function SummaryTable(props: { data: Ingredient[], closeMatchData: Ingredient[], unreviewedData: string[], category: string, columns: ColumnProps<Ingredient>[], loading: boolean }) {
    const categoryFilter = props.category;
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const handleRowExpand = (status: string) => {
        if (expandedRowKeys.includes(status)) {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== status));
        } else {
            setExpandedRowKeys([...expandedRowKeys, status]);
        }
    };

    const columns: ColumnProps<any>[] = [
        {
            title: "Ingredient Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Count",
            dataIndex: "count",
            key: "count",
            render: (count: number | undefined) => count ?? "-",
        },
        {
            title: "Details",
            dataIndex: "details",
            key: "details",
            render: (details: CloseMatchDetails | SummaryDetail[] | undefined, record: { status: string }) => {
                if (record.status === STATUS_LABELS[STATUS_CLOSE_MATCHES] && details && typeof details === "object") {
                    const closeMatchDetails = details as CloseMatchDetails;

                    return (
                        <Collapse
                            activeKey={expandedRowKeys.includes(record.status) ? [record.status] : []}
                            onChange={() => handleRowExpand(record.status)}
                            ghost
                        >
                            <Panel header={``} key={record.status}>
                                <Table
                                    dataSource={[
                                        { category: "Acceptable", values: closeMatchDetails[STATUS_ACCEPTABLE] },
                                        { category: "Unacceptable", values: closeMatchDetails[STATUS_UNACCEPTABLE] },
                                        { category: "Restricted", values: closeMatchDetails[STATUS_RESTRICTED] },
                                    ]}
                                    columns={[
                                        {
                                            dataIndex: 'category',
                                            key: 'category',
                                            render: (text) => text,
                                        },
                                        {
                                            dataIndex: 'values',
                                            key: 'values-count',
                                            render: (values: SummaryDetail[]) => values.length,
                                        },
                                        {
                                            dataIndex: 'values',
                                            key: 'values',
                                            render: (values: SummaryDetail[]) => (
                                                <div>
                                                    {values.map((item, idx) => (
                                                        <span key={idx}>
                                                            {item.ingredientName.map((name, index) => (
                                                                <span key={`${name}-${index}`}>
                                                                {item.qualifier ? (
                                                                    <Popover content={item.qualifier}>
                                                                        {name}
                                                                    </Popover>
                                                                ) : (
                                                                    name
                                                                )}
                                                                    {idx < values.length - 1 && ", "}
                                                            </span>
                                                          ))}
                                                        </span>
                                                    ))}
                                                </div>
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                    showHeader
                                    bordered
                                    size="small"
                                />
                            </Panel>
                        </Collapse>
                    );
                } else if (Array.isArray(details)) {
                    return (
                        <Collapse
                            activeKey={expandedRowKeys.includes(record.status) ? [record.status] : []}
                            onChange={() => handleRowExpand(record.status)}
                            ghost
                        >
                            <Panel header={``} key={record.status}>
                                {details.map((detail, idx) => (
                                    <span key={idx}>
                                      {detail.ingredientName.map((name, index) => (
                                          <span key={`${name}-${index}`}>
                                          {detail.qualifier ? (
                                              <Popover content={detail.qualifier}>
                                                  {name}
                                              </Popover>
                                          ) : (
                                              name
                                          )}
                                              {idx < details.length - 1 && ", "}
                                        </span>
                                      ))}
                                    </span>
                                ))}
                            </Panel>

                        </Collapse>
                    );
                } else {
                    return null;
                }
            },
        },
    ];

    const summaryData = getSummaryData(props.data, props.closeMatchData, props.unreviewedData, categoryFilter);

    return (
        <Table
            loading={props.loading}
            columns={columns}
            dataSource={summaryData}
            rowKey={(record) => record.status}
            pagination={false}
            style={ingredientsTableStyle}
        />
    );
}
