import React, { useEffect, useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getReport, bulkUpload, getMetaData } from "src/api/bulkUploadApi";
import { MetaDataTable } from 'src/components/MetaDataTable';
import {User} from "src/types/user";

interface BulkUploadContainerProp {
    user: User;
}

const BulkUploadContainer: React.FC<BulkUploadContainerProp> = ({user}) => {

    const [loading, setLoading] = useState(false);
    const [metaData, setMetaData] = useState([]);
    const [loadingMetaData, setLoadingMetaData] = useState(true);

    const handleUpload = async (info: any) => {
        const { file } = info;
        setLoading(true);

        try {
            const response = await bulkUpload(file, user.email);
            if (response) {
                message.success(`${file.name} file uploaded successfully.`);
                fetchMetaData(); // Fetch metadata after successful upload
            } else {
                message.error(`Failed to upload file: ${file.name}`);
            }
        } catch (error) {
            message.error(`Error uploading file: ${file.name}`);
        } finally {
            fetchMetaData();
            setLoading(false);
        }
    };

    const fetchMetaData = async () => {
        setLoadingMetaData(true);
        try {
            const data = await getMetaData();
            setMetaData(data);
        } catch (error) {
            message.error('Failed to fetch metadata.');
        } finally {
            setLoadingMetaData(false);
        }
    };

    const handleStatusClick = async (id: string, type: string) => {
        try {
            const response = await getReport(id,type);
            if (response) {
                const link = document.createElement('a');
                link.href = response.url;
                link.download = 'latest-report.csv';
                link.click();
                message.success('Download started for the status report.');
            } else {
                message.error('Failed to get report for the status.');
            }
        } catch (error) {
            message.error('Error while fetching status report.');
        }
    };

    useEffect(() => {
        fetchMetaData();
    }, []);

    return (
        <div style={{textAlign: 'center', marginTop: '20px'}}>
            <h3>Upload CSV file for Bulk Upload</h3>
            <Upload
                name="file"
                customRequest={handleUpload}
                showUploadList={false}
                beforeUpload={() => {
                    setLoading(true);
                    return true;
                }}
                onChange={(info) => {
                    if (info.file.status === 'done') {
                        setLoading(false);
                    }
                }}
            >
                <Button icon={<UploadOutlined/>} loading={loading}>
                    Upload CSV
                </Button>
            </Upload>
            <br/><br/>
            <div>
                <h3>Upload History Table :</h3>
            </div>

            <MetaDataTable
                data={metaData}
                onStatusClick={handleStatusClick}
                loading={loadingMetaData}
            />
        </div>
    );
};

export default BulkUploadContainer;
