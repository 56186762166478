// summaryConstants.ts

export const STATUS_ACCEPTABLE = "acceptable";
export const STATUS_UNACCEPTABLE = "unacceptable";
export const STATUS_RESTRICTED = "restricted";
export const STATUS_UNREVIEWED = "unreviewed";
export const STATUS_CLOSE_MATCHES = "closeMatches";

export const UNACCEPTABLE_WITH_QUALIFIER = "unacceptable-with-qualifier";
export const ACCEPTABLE_WITH_QUALIFIER = "acceptable-with-qualifier";

export const STATUS_LABELS: { [key: string]: string } = {
    [STATUS_ACCEPTABLE]: "Acceptable",
    [STATUS_UNACCEPTABLE]: "Unacceptable",
    [STATUS_RESTRICTED]: "Restricted Use (Listed Requirements Must be Met)",
    [STATUS_UNREVIEWED]: "Not Reviewed (Requires Approval)",
    [STATUS_CLOSE_MATCHES]: "Close Matches to Not Reviewed Ingredients"
};

// Interfaces for summary data and details

export interface SummaryDetail {
    ingredientName: string[];
    ingredientId: string[];
    qualifier: string;
}

export interface CloseMatchDetails {
    [STATUS_ACCEPTABLE]: SummaryDetail[];
    [STATUS_UNACCEPTABLE]: SummaryDetail[];
    [STATUS_RESTRICTED]: SummaryDetail[];
}

export interface SummaryData {
    status: string;
    count: number;
    details: SummaryDetail[] | CloseMatchDetails;
}
