import { filteredIdsForApi } from "src/containers/SearchContainer";
import {Ingredient} from "src/types/ingredient";
import {getName} from "src/utils/commonUtils";

export const filterIngredients = (
    ingredients: Ingredient[],
    classificationFilters: string[],
    authorFilters: string[],
    visibilityFilters: string[],
    filterTypes: string[],
) => {
    const seenIds = new Set<string>();
    const filteredIngredients: Ingredient[] = ingredients.filter(ingredient => {
        const authorName = getName(ingredient.createdBy);
        if (!(classificationFilters.includes(ingredient.classification) && authorFilters.includes(authorName) && visibilityFilters.includes(ingredient.visibility))) {
            return false;
        }
        if (!(ingredient.acceptability.find(acc => filterTypes.includes(acc.type)))) {
            return false;
        }
        if (seenIds.has(ingredient.id)) {
            return false; // Duplicate found, filter it out
        }
        seenIds.add(ingredient.id);
        return true;
    });
    filteredIdsForApi.clear();
    filteredIngredients.forEach(ingredient => filteredIdsForApi.add(ingredient.id));
    return filteredIngredients;
};

export const filterIngredient = (
    ingredients: Ingredient[],
    classificationFilters: string[],
    authorFilters: string[],
    visibilityFilters: string[],
    filterType: string,
) => {
    const seenIds = new Set<string>();
    const filteredIngredients: Ingredient[] = ingredients.filter(ingredient => {
        const authorName = getName(ingredient.createdBy);
        if (!(classificationFilters.includes(ingredient.classification) && authorFilters.includes(authorName) && visibilityFilters.includes(ingredient.visibility))) {
            return false;
        }
        if (seenIds.has(ingredient.id)) {
            return false; // Duplicate found, filter it out
        }
        seenIds.add(ingredient.id);
        return true;
    });
    // Clear the set before adding new values
    filteredIdsForApi.clear();
    // Add new values to the set
    filteredIngredients.forEach(ingredient => filteredIdsForApi.add(ingredient.id));
    return filteredIngredients;
};
