import React, { useEffect, useState } from "react";
import type { ColumnProps } from "antd/es/table";
import { Table } from "antd";
import { Ingredient } from "src/types/ingredient";
import { Link, useLocation } from "react-router-dom";
import { capitalize } from "src/utils/commonUtils";
import { ingredientsTableStyle } from "src/constants/styleConstants";

export function IngredientsTable(props: { data: Ingredient[], columns: ColumnProps<Ingredient>[], loading: boolean, admin: boolean }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState<Ingredient[]>([]);
    const location = useLocation();

    useEffect(() => {
        // Reset the page number when location.pathname changes
        setCurrentPage(1);

        // Get the ingredient name from the query parameter
        const queryParams = new URLSearchParams(location.search);
        const ingredientName = queryParams.get("ingredient");

        if (ingredientName) {
            setFilteredData(props.data.filter(ingredient => ingredient.name.includes(ingredientName)));
        } else {
            setFilteredData(props.data);
        }
    }, [location.pathname, location.search, props.data]);

    const handlePaginationChange = (page: React.SetStateAction<number>) => {
        setCurrentPage(page); // Save or use the page number as needed
    };

    return (
        <Table
            loading={props.loading}
            columns={props.columns}
            expandable={props.admin ? {} : {
                expandedRowRender: record => {
                    // display masters of ingredients as expanded entry
                    return (
                        <div>
                            {Object.keys(record.masters).map(masterKey => (
                                <p key={masterKey}>{capitalize(masterKey)}: <br/>
                                    {record.masters[masterKey].map((masterRecord: { [x: string]: string; }) =>
                                        <Link key={masterRecord["name"]} to={`/?ingredients=${masterRecord["name"]}`}>{masterRecord["name"].toUpperCase()}<br/></Link>)}
                                </p>
                            ))}
                            {Array.from(record.functions).length > 0 ? <p>Functions: <br/>
                                {Array.from(record.functions).map(functionMeta =>
                                    // @ts-ignore
                                    <><Link to={`/glossary/${functionMeta["id"]}`}>{functionMeta["name"].toUpperCase()}</Link><br/></>
                                )}
                            </p> : null}
                        </div>
                    );
                },
                expandRowByClick: true
            }}
            style={ingredientsTableStyle}
            dataSource={filteredData}
            rowKey={record => record.id}
            pagination={{
                current: currentPage,
                onChange: handlePaginationChange,
            }}
            scroll={{x: "calc(700px + 50%)"}}
        />
    );
}
