import {apiConfig} from "src/config";
import { fetchWithTokenAndBody} from "src/api/authentication";
import { POST} from "src/constants/apiConstants";
import {Ingredient} from "src/types/ingredient";

const OPENSEARCH_API_ENDPOINT = `${apiConfig.API_ENDPOINT}/ingredients/openSearch`;

const searchIngredients = async (searchedValues: string[]): Promise<any|null> => {

    const response = await fetchWithTokenAndBody(
        `${OPENSEARCH_API_ENDPOINT}`,
        POST,
        (searchedValues.filter((value) => value.length > 2))
    );

    if (response) return response.data;
    return null;
};
const sendFeedback = async (
    searchedValues: string[],
    feedback: string,
    exactSearchResultsArray: Ingredient[],
    exactSearchTokenResultsArray: Ingredient[],
    exactSearchResultsNotFoundArray: string[]
): Promise<any | null> => {

    const requestBody = {
        type: "feedback",
        searchedValues: searchedValues,
        feedback: feedback,
        exactSearchResultsArray: exactSearchResultsArray,
        exactSearchTokenResultsArray: exactSearchTokenResultsArray,
        exactSearchResultsNotFoundArray: exactSearchResultsNotFoundArray
    };

    const response = await fetchWithTokenAndBody(
        `${OPENSEARCH_API_ENDPOINT}`,
        POST,
        requestBody
    );

    if (response) return response.message;
    return null;
};


export {
    searchIngredients,
    sendFeedback
}