import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import leafLogo from 'src/images/Quality-Standards-Leaf-Icon.jpg';
import wfmLogo from 'src/images/WFM_Logo.png';
import {Alert, Button, ConfigProvider, Layout} from "antd";
import SearchContainer from "src/containers/SearchContainer";
import SummaryContainer from "src/containers/SummaryContainer";
import {PRIMARY_COLOR} from "src/constants/colorContants";
import {HEADER_FONT_SIZE, HEADER_IMAGE_HEIGHT} from "src/constants/sizeContants";
import {Navigation} from "src/components/common/Navigation";
import {GlossaryFunctions} from "src/containers/GlossaryFunctions";
import {FunctionDescription} from "src/containers/FunctionDescription";
import {Auth} from "aws-amplify";
import EditIngredients from "src/components/EditIngredients";
import {User} from "src/types/user";
import NotFoundPage from "src/components/common/NotFoundPage";
import {Role} from "src/constants/enums";
import {
    ADD_INGREDIENT_PAGE,
    ADMIN_PAGE, BULK_UPLOAD_PAGE,
    CUSTOM_ROLE, EDIT_INGREDIENT_PAGE,
    FEDERATE, FUNCTION_DESCRIPTION_PAGE,
    GLOSSARY_PAGE,
    HOME_PAGE, NOT_FOUND_PAGE, SUMMARY_PAGE
} from "src/constants/appConstants";
import {appContentStyle, appFooterStyle, appHeaderStyle, appLayoutStyle} from "src/constants/styleConstants";
import "./App.scss";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";
import BulkUploadContainer from "src/containers/BulkUploadContainer";


function ItemMissing() {
    const metricsPublisher = getMetricsPublisher();
    const initialTime = Date.now();
    useEffect(() => {
        metricsPublisher.publishTimerMetric(NOT_FOUND_PAGE,
          MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
        metricsPublisher.publishCounterMetric(NOT_FOUND_PAGE, MetricKey.CLICKS, 1);
    }, []);
    return <div className="item-not-found">
        <h2></h2>
        <p>
            There’s no definition for this term in our glossary. <br/>
            Contact <a href="mailto:Quality.Standards@wholefoods.com">Quality.Standards@wholefoods.com</a> if you need more information.
        </p>
    </div>
}

enum AppStage {
    LOGIN_REQUIRED,
    LOGGING_IN,
    LOGGED_IN
}

export const App: React.FC = () => {
    const [appStage, setAppStage] = useState<AppStage>(AppStage.LOGGING_IN)
    const [userRole, setUserRole] = useState(Role.SUBSCRIBER)
    const [user, setUser] = useState<User>();
    const [isAdmin, setIsAdmin] = useState(false)

    useLayoutEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            const userEmail = user.signInUserSession.idToken.payload.email;
            const userRoleFederate = user.signInUserSession.idToken.payload[CUSTOM_ROLE];
            setUser({email: userEmail, role: userRoleFederate});
            if (user) {
                console.debug(user);
                setAppStage(AppStage.LOGGED_IN)
                setUserRole(userRoleFederate)
                setIsAdmin(userRoleFederate === Role.ADMIN)
            } else {
                setAppStage(AppStage.LOGGING_IN)
                setAppStage(AppStage.LOGGED_IN)
                setUserRole(userRoleFederate);
                setIsAdmin(userRoleFederate === Role.ADMIN)
                Auth.federatedSignIn({customProvider: FEDERATE}).then(credentials => {
                    console.debug(credentials);
                }).catch(console.error);
            }
        }).catch(err => {
            console.error(err);
            setAppStage(AppStage.LOGGING_IN)
            Auth.federatedSignIn({customProvider: FEDERATE}).then(credentials => {
                console.debug(credentials);
            }).catch(console.error);
        });
    }, [appStage, userRole, isAdmin])

    return (
        <>
            {
                appStage == AppStage.LOGGING_IN && <Alert type="info" message={"Logging in..."}/>
            }
            {
                appStage == AppStage.LOGIN_REQUIRED &&
                <Alert type="error" message={"Permission Denied. This website is restricted to members of WFM group."}/>
            }
            {   appStage == AppStage.LOGGED_IN &&
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: PRIMARY_COLOR,
                        },
                    }}
                >
                    <Layout style={appLayoutStyle}>
                        <Layout.Header style={appHeaderStyle}>
                            <div className={"app-layout-header"}>
                                <img src={leafLogo} style={{height: HEADER_IMAGE_HEIGHT}} alt="Logo"/>
                                <h1 style={{fontSize: HEADER_FONT_SIZE}}>Whole Foods Market Ingredients List</h1>
                            </div>
                            <Button onClick={() => Auth.signOut()}>Logout</Button>
                        </Layout.Header>
                        <Layout>
                            <Layout>
                                <Router>
                                    <Navigation admin={isAdmin}></Navigation>
                                    <Layout.Content style={appContentStyle}>
                                        <Routes>
                                            <Route path={HOME_PAGE} element={<SearchContainer admin={false}/>}/>
                                            <Route path={SUMMARY_PAGE} element={<SummaryContainer admin={false}/>}/>
                                            <Route path={BULK_UPLOAD_PAGE} element={user && <BulkUploadContainer user={user} />}/>
                                            <Route path={GLOSSARY_PAGE} element={<GlossaryFunctions admin={isAdmin}/>}/>
                                            <Route path={ADMIN_PAGE} element={<SearchContainer admin={isAdmin}/>}/>
                                            <Route path={ADD_INGREDIENT_PAGE} element={ user && <EditIngredients user={user} edit={false}/>}/>
                                            <Route path={EDIT_INGREDIENT_PAGE} element={ user && <EditIngredients user={user} edit={true}/>}/>
                                            <Route path={FUNCTION_DESCRIPTION_PAGE} element={<FunctionDescription admin={isAdmin}></FunctionDescription>}/>
                                            <Route path={NOT_FOUND_PAGE} element={<ItemMissing/>}/>
                                            <Route path="*" element={<NotFoundPage/>}/>
                                        </Routes>
                                    </Layout.Content>
                                </Router>
                            </Layout>
                        </Layout>
                        <Layout.Footer style={appFooterStyle}>
                            <img src={wfmLogo} style={{height: HEADER_IMAGE_HEIGHT}} alt="Logo"/>
                            <p>Copyright@ 2023 Whole Foods Market IP, Inc</p>
                            <p>"Whole Foods Market" is a registered trademark of Whole Foods Market IP, Inc</p>
                        </Layout.Footer>
                    </Layout>
                </ConfigProvider>
            }
        </>
    );
}