import { apiConfig } from "src/config";
import {fetchWithToken,fetchWithTokenAndBody,getAuthenticatedUserToken} from "src/api/authentication";
import {GET, POST} from "src/constants/apiConstants";
import {Function} from "src/types/function";

const BULK_UPLOAD_API_ENDPOINT = `${apiConfig.API_ENDPOINT}/ingredients/bulkUpload`;

const bulkUpload = async (file: File, email: String): Promise<any | null> => {
    if (!file || file.size === 0) {
        console.error('No file provided or file is empty');
        return null;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
            const idToken = await getAuthenticatedUserToken();
            const result = reader.result;
            if (typeof result === 'string') {
                const base64data = result.split(',')[1];
                const payload = {
                    fileName: file.name,
                    fileData: base64data,
                    email: email,
                    token: idToken,
                };

                try {
                    console.log("sending",JSON.stringify(payload))
                    const response = await fetchWithTokenAndBody(
                        BULK_UPLOAD_API_ENDPOINT,
                        POST,
                        payload
                    );

                    if (response) {
                        resolve(response.message);
                    } else {
                        resolve(null);
                    }
                } catch (error) {
                    reject(error);
                }
            } else {
                reject(new Error('Failed to read the file as a string.'));
            }
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
            reject(error);
        };
    });
};

const getReport = async (id: string, type: string): Promise<any|null> => {
    const response = await fetchWithToken(`${BULK_UPLOAD_API_ENDPOINT}?id=${id}&type=${type}`, GET);
    if (response && response.message) {
        return { url: response.message };
    }
    return null;
}

const getMetaData = async (): Promise<any|null> => {
    const response = await fetchWithToken(BULK_UPLOAD_API_ENDPOINT, GET);
    if (response) {
        return response.data;
    }
    return null;
}

export {
    bulkUpload,
    getReport,
    getMetaData
};
