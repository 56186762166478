// already configured in the webpack from katal manifest
const stage = 'prod'; // 'test' | 'beta' | 'gamma' | 'prod'

const stageEnvVariables: Record<string, any> = {
    ['test']: {
        USER_POOL_ID : "us-east-1_gNVX7jlIe",
        USER_POOL_CLIENT_ID : "6imedjo86gtgocpal0mtkmh9ap",
        IDENTITY_POOL_ID : "us-east-1:1bbc38e5-4a42-4548-a242-d3f8e2225092",
        REGION : "us-east-1",
        OAUTH_DOMAIN : "wfmingredients-beta.auth.us-east-1.amazoncognito.com",
        API_ENDPOINT: "https://x3gpd36yj8.execute-api.us-east-1.amazonaws.com/beta"
    },
    ['beta']: {
        USER_POOL_ID : "us-east-1_gNVX7jlIe",
        USER_POOL_CLIENT_ID : "6imedjo86gtgocpal0mtkmh9ap",
        IDENTITY_POOL_ID : "us-east-1:1bbc38e5-4a42-4548-a242-d3f8e2225092",
        REGION : "us-east-1",
        OAUTH_DOMAIN : "wfmingredients-beta.auth.us-east-1.amazoncognito.com",
        API_ENDPOINT: "https://x3gpd36yj8.execute-api.us-east-1.amazonaws.com/beta"
    },
    ['gamma']: {
        USER_POOL_ID : "us-east-1_fjTXOzYsH",
        USER_POOL_CLIENT_ID : "3q76ag531fuk8gk73b5e86cj94",
        IDENTITY_POOL_ID : "us-east-1:5ced96cb-8dd0-46d0-9f90-9004f90ab296",
        REGION : "us-east-1",
        OAUTH_DOMAIN : "wfmingredients-gamma.auth.us-east-1.amazoncognito.com",
        API_ENDPOINT: "https://xmrr5riyjk.execute-api.us-east-1.amazonaws.com/gamma"
    },
    ['prod']: {
        USER_POOL_ID : "us-east-1_qoBjPJx79",
        USER_POOL_CLIENT_ID : "3p5uq87gbq3a08psd68qdcinb7",
        IDENTITY_POOL_ID : "us-east-1:4ec668d4-5d77-4c5f-ba70-e0be6040af3e",
        REGION : "us-east-1",
        OAUTH_DOMAIN : "wfmingredients.auth.us-east-1.amazoncognito.com",
        API_ENDPOINT: "https://9kv1klgum0.execute-api.us-east-1.amazonaws.com/prod"
    }
}

// default to 'test' for unit test cases
const stageVariables = stageEnvVariables[stage] || stageEnvVariables['test'];

const oauth = {
    domain: stageVariables.OAUTH_DOMAIN,
    scope: ['openid', 'profile'],
    redirectSignIn: window.location.protocol + '//' + window.location.host,
    redirectSignOut: window.location.protocol + '//' + window.location.host,
    responseType: 'code',
    options: {
        AdvancedSecurityDataCollectionFlag: false,
    },
};

const amplifyConfig: Record<string, any> = {
    Auth: {
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: stageVariables.USER_POOL_ID,
        userPoolWebClientId: stageVariables.USER_POOL_CLIENT_ID,
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: stageVariables.IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: stageVariables.REGION,
        oauth: oauth
    },
}

const apiConfig = {
    API_ENDPOINT: stageVariables.API_ENDPOINT
}

export {
    amplifyConfig,
    apiConfig
}